import { v4 } from 'uuid'
import { deepClone } from '@/utils/deepClone'
import { DateTime } from 'luxon'
import {
  datePredefined,
  noFutureDatesPredefined,
  userPredefined,
} from '@/utils/predefined'
import { store } from '@/state/store'
import { capitalize } from '../utils/string'

export const setReferralsFilterValues = (filterValues) => {
  store.dispatch('referrals/clearReferralsFilterValues')
  store.dispatch('referrals/setReferralsFilterValues', filterValues)
}

export const setReferralsFilters = (rowProps, column, currentUser) => {
  let referralFilters = []

  if (rowProps?.item?.company_id) {
    const companyFilter = {
      column: {
        _t_id: '34be80a0-512d-45fa-8b10-9bf77df2f981',
        prop: 'companyId',
        text: 'Company ID',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
      },
      value: rowProps.item.company_id.toString(),
    }
    referralFilters = referralFilters.concat(addParentFilter(companyFilter))
  }
  if (rowProps?.item?.market_id) {
    const marketFilter = {
      column: {
        _t_id: '2669e917-64a0-4f86-996e-601e1ffb972b',
        prop: 'trip.pricingMarket.marketId',
        text: 'Market ID',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
      },
      value: rowProps.item.market_id.toString(),
    }
    referralFilters = referralFilters.concat(addParentFilter(marketFilter))
  }

  if (rowProps?.item?.agent_name) {
    const bookedByNameFilter = {
      column: {
        _t_id: '568dd83a-de61-426d-9499-a32e22142af1',
        prop: 'bookedByName',
        text: 'Booked By',
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        filterType: 'contains',
        predefined: userPredefined,
      },
    }
    bookedByNameFilter.column.predefined[0].id = uuidv4()
    bookedByNameFilter.column.predefined[0].controls[0].value =
      rowProps?.item?.agent_name
    bookedByNameFilter.selectedPredefined = deepClone(
      bookedByNameFilter.column.predefined[0]
    )

    referralFilters = referralFilters.concat(
      addParentFilter(bookedByNameFilter)
    )
  }

  if (column.cancellationType >= 0) {
    const cancellationTypeFilter = {
      column: {
        _t_id: '277e062b-0ab9-4d6d-ab18-71c4f77347eb',
        prop: 'parentReservation.cancellationClassificationId',
        text: 'Cancellation Type',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
        filterAsIs: true,
      },
      value: column.cancellationType.toString(),
    }
    referralFilters = referralFilters.concat(
      addParentFilter(cancellationTypeFilter)
    )
  } else if (column.cancellationType == -1) {
    const cancellationTypeFilter = {
      column: {
        _t_id: 'b54b2933-dd8c-405d-83a7-51537a44ac98',
        prop: 'parentReservation.cancellationClassificationId',
        text: 'Cancellation Type',
        sort: true,
        filter: true,
        filterType: 'isnull',
        filterAsIs: true,
      },
      value: 0,
    }
    referralFilters = referralFilters.concat(
      addParentFilter(cancellationTypeFilter)
    )
  }

  if (column.marketplaceFilter) {
    const marketplaceFilter = {
      column: {
        _t_id: '872ba896-8921-496a-9290-2cc2beda7ffe',
        prop: 'charterUpQuote',
        text: 'Marketplace',
        computedText: (item) => (item ? 'Yes' : 'No'),
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        sort: true,
        filter: true,
        filterType: 'eq',
        defaultHidden: false,
        predefined: [
          {
            text: 'Yes',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 1,
          },
          {
            text: 'No',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 0,
          },
        ],
      },
    }
    if (column.marketplaceFilter === 'marketplace') {
      marketplaceFilter.value = 1
      referralFilters = referralFilters.concat(
        addParentFilter(marketplaceFilter)
      )
    } else if (column.marketplaceFilter === 'non-marketplace') {
      marketplaceFilter.value = 0
      referralFilters = referralFilters.concat(
        addParentFilter(marketplaceFilter)
      )
    }
  }
  if (column.referralCreationMethodFilter) {
    switch (column.referralCreationMethodFilter) {
      case 'autoReferred': {
        const autoReferredFilter = {
          column: {
            _t_id: '3adbf226-e02b-4e5f-aba3-25c8004a696c',
            prop: 'isAutoReferred',
            text: 'Auto Referred',
            computedText: (item) => (item ? 'Yes' : 'No'),
            customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
            sort: true,
            filter: true,
            filterType: 'eq',
            defaultHidden: false,
            predefined: [
              {
                text: 'Yes',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 1,
              },
              {
                text: 'No',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 0,
              },
            ],
          },
          value: 1,
        }
        referralFilters = referralFilters.concat(
          addParentFilter(autoReferredFilter)
        )
        break
      }
      case 'referredOnConvert': {
        const referredOnConvertFilter = {
          column: {
            _t_id: '065e9273-328e-4aa1-b861-494e47adc290',
            prop: 'referredOnConvert',
            text: 'Referred On Convert',
            computedText: (item) => (item ? 'Yes' : 'No'),
            customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
            sort: true,
            filter: true,
            filterType: 'eq',
            predefined: [
              {
                text: 'Yes',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 1,
              },
              {
                text: 'No',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 0,
              },
            ],
            detail: false,
            defaultHidden: false,
          },
          value: 1,
        }
        referralFilters = referralFilters.concat(
          addParentFilter(referredOnConvertFilter)
        )
        break
      }
      case 'manuallyReferred': {
        const notAutoReferredFilter = {
          column: {
            _t_id: '3adbf226-e02b-4e5f-aba3-25c8004a696c',
            prop: 'isAutoReferred',
            text: 'Auto Referred',
            computedText: (item) => (item ? 'Yes' : 'No'),
            customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
            sort: true,
            filter: true,
            filterType: 'eq',
            defaultHidden: false,
            predefined: [
              {
                text: 'True',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 1,
              },
              {
                text: 'False',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 0,
              },
            ],
          },
          value: 0,
        }
        referralFilters = referralFilters.concat(
          addParentFilter(notAutoReferredFilter)
        )
        const notReferredOnConvertFilter = {
          column: {
            _t_id: '065e9273-328e-4aa1-b861-494e47adc290',
            prop: 'referredOnConvert',
            text: 'Referred On Convert',
            computedText: (item) => (item ? 'Yes' : 'No'),
            customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
            sort: true,
            filter: true,
            filterType: 'eq',
            predefined: [
              {
                text: 'Yes',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 1,
              },
              {
                text: 'No',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 0,
              },
            ],
            detail: false,
            defaultHidden: false,
          },
          value: 0,
        }
        referralFilters = referralFilters.concat(
          addParentFilter(notReferredOnConvertFilter)
        )
        break
      }
    }
    const fromDate = column.fromDate
    const toDate = column.toDate

    if (fromDate && toDate) {
      if (column.dateToFilterOn === 'pickup_date') {
        const column = {
          _t_id: 'c1a4aa76-e5e7-4cc1-b040-a817067accfe',
          prop: 'pickupDate',
          text: 'Pickup Date',
          computedText: (item, row) =>
            DateTime.fromISO(item, {
              zone: row.firstPickupTimeZone,
            }).toFormat('MM/dd/yyyy t ZZZZ'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          forceOneLine: true,
        }
        delete column.predefined[0].controls[0].recalculate
        delete column.predefined[0].controls[1].recalculate
        column.predefined[0].defaultOnSelection = true
        column.predefined[0].controls[0].displayValue = fromDate
        column.predefined[0].controls[0].value = fromDate
        column.predefined[0].controls[1].displayValue = toDate
        column.predefined[0].controls[1].value = toDate
        column.predefined[0].id = uuidv4()
        const pickupDateFilter = {
          column,
          selectedPredefined: column.predefined[0],
        }
        referralFilters = referralFilters.concat(
          addParentandParentOrFilter([pickupDateFilter])
        )
      } else {
        const column = {
          _t_id: '6ddd4f83-e6ae-4d98-b8f2-d7b85bfbda91',
          prop: 'createdOn',
          text: 'Created Date',
          computedText: (item) =>
            item
              ? DateTime.fromISO(item, {
                  zone: currentUser?.company?.address?.timeZone,
                }).toFormat('MM/dd/yyyy t ZZZZ')
              : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: false,
          predefined: deepClone(noFutureDatesPredefined),
          defaultSort: true,
        }
        delete column.predefined[0].controls[0].recalculate
        delete column.predefined[0].controls[1].recalculate
        column.predefined[0].defaultOnSelection = true
        column.predefined[0].controls[0].displayValue = fromDate
        column.predefined[0].controls[0].value = fromDate
        column.predefined[0].controls[1].displayValue = toDate
        column.predefined[0].controls[1].value = toDate
        column.predefined[0].id = uuidv4()
        const createdOnDateFilter = {
          column,
          selectedPredefined: column.predefined[0],
        }
        referralFilters = referralFilters.concat(
          addParentandParentOrFilter([createdOnDateFilter])
        )
      }
    }
  }

  if (rowProps?.item?.cancelled_referrals) {
    const parentStatusFilter = {
      column: {
        _t_id: '0d9b1e42-038c-42fc-9311-85449d748e52',
        prop: 'parentReservation.reservationStatus',
        text: 'Parent Status',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        detail: false,
        defaultHidden: false,
        computedText: (item, row) =>
          row.reservationStatus ? capitalize(row.reservationStatus) : '',
        predefined: [
          {
            text: 'Upcoming',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 'upcoming',
          },
          {
            text: 'Started',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 'started',
          },
          {
            text: 'Finished',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 'finished',
          },
          {
            text: 'On Hold',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 'hold',
          },
          {
            text: 'Cancelled',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 'cancelled',
          },
        ],
      },
      value: 'cancelled',
    }
    referralFilters = referralFilters.concat(
      addParentFilter(parentStatusFilter)
    )

    const rejectionMethodFilter = {
      column: {
        _t_id: 'f474abb4-ec79-4d36-a45b-133c5e02c02a',
        prop: 'rejectionMethod',
        text: 'Rejection Method',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
        detail: false,
        defaultHidden: false,
      },
      value: 4,
    }
    const referredOnConvertFilter = {
      column: {
        _t_id: '065e9273-328e-4aa1-b861-494e47adc290',
        prop: 'referredOnConvert',
        text: 'Referred On Convert',
        computedText: (item) => (item ? 'Yes' : 'No'),
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        sort: true,
        filter: true,
        filterType: 'eq',
        predefined: [
          {
            text: 'Yes',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 1,
          },
          {
            text: 'No',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 0,
          },
        ],
        detail: false,
        defaultHidden: false,
      },
      value: 1,
    }
    referralFilters = referralFilters.concat(
      addParentandParentOrFilter([
        rejectionMethodFilter,
        referredOnConvertFilter,
      ])
    )
  }

  return referralFilters
}

const addParentFilter = (filter) => {
  let filters = []
  const parentFilter = {
    column: {
      _t_id: uuidv4(),
    },
    method: 'and',
  }
  filter.parent = parentFilter.column._t_id
  filters.push(parentFilter, filter)
  return filters
}
const addParentandParentOrFilter = (orFilters) => {
  let filters = []
  const grandParentFilter = {
    column: {
      _t_id: uuidv4(),
    },
    method: 'and',
  }
  const parentFilter = {
    column: {
      _t_id: uuidv4(),
    },
    method: 'or',
  }
  parentFilter.parent = grandParentFilter.column._t_id
  for (let filter of orFilters) {
    filter.parent = parentFilter.column._t_id
    filters.push(filter)
  }
  filters.push(grandParentFilter, parentFilter)
  return filters
}
const uuidv4 = () => {
  return v4()
}
